.switch {

  position: relative;
  display: inline-block;
  margin: 1% auto 1% auto;
  width: 90px;
  height: 50px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 33px;
  width: 33px;
  left: 8px;
  bottom: 8px;
  border-radius: 50%;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #32d574;
}

input:checked + .slider:before {
  transform: translateX(39px);
}

