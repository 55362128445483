.header {
  background-color: #333;
  overflow: hidden;
}

.header .li {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.header .li:hover {
  background-color: #ddd;
  color: black;
}