.blob {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color:green;
  border-radius: 50%;
  margin: 30px;
  aspect-ratio: 1/1;
}

.blobAnim {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  border-style: solid;
  border-color:green;
  border-radius: 50%;
  aspect-ratio: 1/1;
  transform: scale(1);
  animation: pulse 1.2s infinite;
}

.logodiv {
  width: 50%;
}

.logodivAnim {
  width: 50%;
  border-radius: 50%;

  box-shadow: 0 0 0 0 rgb(177, 202, 13);
  transform: scale(1);
  animation: pulse2 1.2s infinite;
}

@keyframes pulse {
0% {
  transform: scale(0.95);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 40px rgba(255, 238, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    background-color: rgb(38, 202, 13);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 40px rgba(255, 238, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  }